<template>
  <div>
    <div v-if='loading'>
      <b-row>
        <b-col cols='12'>
          <b-spinner></b-spinner>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-card-header>
        <b-card-title>BigQuery Provider History</b-card-title>
      </b-card-header>
      <b-card no-body>
        <div v-if='isNullOrEmpty(ingestions)' class='mt-4 mb-2 ml-2 mr-2'>
          <b-alert show variant='danger'>No ingestions found for this event</b-alert>
        </div>
        <div v-else>
          <b-tabs pills card vertical nav-wrapper-class='col-2'>
            <b-tab lazy v-for='(item, index) in ingestions'
                   :title="'Ingestion ' + (parseInt((ingestions.length - index), 10))"
                   :key='index'
                   @click.prevent='onTabChange(item.uuid)'>
              <b-card-text>
                <b-row>
                  <b-col>
                    <h5>Ingestion details</h5>
                  </b-col>
                </b-row>
                <div v-if='loadingDetails'>
                  <b-row>
                    <b-col cols='12'>
                      <b-spinner></b-spinner>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <IngestionDetails :ingestion='ingestion'></IngestionDetails>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import IngestionDetails from '@/components/SFTP/BigQueryImporter/IngestionDetails.vue';

export default {
  name: 'ProviderHistory',
  components: { IngestionDetails },
  data() {
    return {
      loading: true,
      ingestions: [],
      loadingDetails: true,
      ingestion: null,
    };
  },
  async beforeMount() {
    await this.getProviderIngestions();
    await this.getLastIngestion();
  },
  methods: {
    isNullOrEmpty,
    async getProviderIngestions() {
      try {
        this.loading = true;
        const { data } = await this.$store.dispatch('Sftp/BigqueryImporter/getIngestions', {
          providerId: this.$route.params.providerId,
        });
        this.ingestions = data;
      } catch (error) {
        this.$noty.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async getLastIngestion() {
      this.loadingDetails = true;
      try {
        const { data } = await this.$store.dispatch('Sftp/BigqueryImporter/getLastIngestionWithoutPolling', {
          providerId: this.$route.params.providerId,
        });
        if (!isNullOrEmpty(data)) {
          this.ingestion = data;
        }
      } catch (error) {
        this.lastIngestion = null;
        this.$noty.error(error.message);
      } finally {
        this.loadingDetails = false;
      }
    },
    async getIngestion(id) {
      this.loadingDetails = true;
      try {
        const { data } = await this.$store.dispatch('Sftp/BigqueryImporter/getIngestion', {
          ingestionId: id,
        });
        this.ingestion = data;
      } catch (error) {
        this.ingestion = null;
        this.$noty.error(error.message);
      } finally {
        this.loadingDetails = false;
      }
    },
    async onTabChange(id) {
      if (this.ingestion.ingestion_id === id) return;
      await this.getIngestion(id);
    },
  },
};
</script>
