<template>
  <div>
    <BigQueryProviderHistory></BigQueryProviderHistory>
  </div>
</template>

<script>

import BigQueryProviderHistory from '@/components/SFTP/BigQueryImporter/History.vue';

export default {
  name: 'ProviderHistory',
  components: { BigQueryProviderHistory },
};

</script>
