<template>
  <div>
    <b-row v-if='ingestion && ingestion.is_completed' class='ingestion-container mt-2 ml-2 mr-2'>
      <b-col class='ml-2'>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Filename:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ ingestion.filename }}</span>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Delimiter:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ convertToChar(ingestion.delimiter) }}</span>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>File has headers:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ ingestion.has_headers ? 'True' : 'False' }}</span>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Skip rows:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ ingestion.skip_rows || 0 }}</span>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Success:</label>
          </b-col>
          <b-col cols='9'>
            <b-badge v-if='ingestion.success' variant='success'>Yes</b-badge>
            <b-badge v-else variant='danger'>No</b-badge>
          </b-col>
        </b-row>
        <b-row v-if='ingestion.error_message' class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Error Message:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ ingestion.error_message}}</span>
          </b-col>
        </b-row>
        <b-row v-if='ingestion.bigquery_table' class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>BigQuery Table:</label>
          </b-col>
          <b-col cols='9' class="code">
            <b-row align-v="center">
              <b-col cols='11'>
                <span >{{ `${ingestion.project_id}.${ingestion.dataset}.${ingestion.bigquery_table}` }}</span>
              </b-col>
              <b-col cols='1' >
                <b-row align-h="end">
                  <span title="Copy content to clipboard"
                        class="btn btn-info btn-sm"
                        @click.stop.prevent="copyTestingCode(
                        `${ingestion.project_id}.${ingestion.dataset}.${ingestion.bigquery_table}`
                        )">
                        <feather type="clipboard"/>
                  </span>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-1'>
          <b-col cols='2'>
            <label class='ingestion-label'>Ingested At:</label>
          </b-col>
          <b-col cols='9'>
            <span>{{ normalizeDate(ingestion.ingested_at) }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else-if='(ingestion && !ingestion.is_completed) || inProgress'>
      <b-col class='ml-2'>
        <span>Ingestion in progress...</span>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class='ml-2'>
        <span>No previous ingestion found</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { isNullOrEmpty } from '@/helpers';

export default {
  name: 'IngestionDetails',
  props: {
    ingestion: {
      type: Object,
      default: () => { },
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    normalizeDate(date) {
      if (isNullOrEmpty(date)) {
        return 'No file ingested yet';
      }
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    convertToChar(code) {
      try {
        return String.fromCharCode(code);
      } catch (e) {
        return 'Invalid code';
      }
    },
    copyTestingCode(value) {
      return navigator.clipboard.writeText(value)
        .then(() => this.$noty.success('Copied to clipboard'));
    },
  },
};
</script>

<style scoped>
.ingestion-container {
  border: 1px solid #ccc;
}

.ingestion-label {
  font-weight: bold;
  font-size: 15px;
}

.code {
  border: 1px solid #ccc;
  border-radius: 6px;
}
</style>
